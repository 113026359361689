<template>
  <div class="cms-homepage-hero-carousel-container">
    <SfHero
      :slider-options="{
        autoplay: playAutoSlide ? changeTime : false,
        swipeThreshold: 20
      }"
    >
      <li
        v-for="(item, index) in bannersGroup"
        :key="index"
        class="glide__slide"
      >
        <component
          :is="item.type"
          :position="index + 1"
          :is-home="true"
          :index="index"
          :block-id="getBlockId"
          v-bind="{...item.props, observeIndividualItem}"
        />
      </li>
    </SfHero>
  </div>
</template>

<script>
import { SfCarousel, SfHero } from '@storefront-ui/vue';

export default {
  name: 'HomepageHeroCarousel',
  components: {
    SfCarousel,
    SfHero,
    hero_banner: () => import('src/modules/storyblok/components/HeroBanner.vue')
  },
  props: {
    bannersGroup: {
      type: Array,
      default: () => []
    },
    changeTime: {
      type: Number,
      default: 5000
    },
    observeIndividualItem: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    playAutoSlide: false
  }),
  mounted () {
    this.playAutoSlide = true
  },
  computed: {
    getBlockId: function () {
      return this.$attrs['block-id']
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
.cms-homepage-hero-carousel-container {
  margin-top: 120px;
  min-height: 600px;
  @include for-mobile {
    margin-top: 106px;
    min-height: 400px;
  }
  ::v-deep {
    .video-container #cf-player {
      @include for-mobile {
        height: unset;
        margin-top: unset;
        max-width: unset;
      }
    }
  }
}
.sf-hero {
  min-height: 600px;
  // max-width: 1440px;
  margin: 0 auto;
  @include for-mobile {
    min-height: 400px;
  }
  ::v-deep .sf-hero__controls {
    display: none;
  }
  ::v-deep .sf-hero__bullets {
    display: block;
    bottom: 25px;
    min-height: 16px;
    max-height: 16px;
    --bullet-background: var(--lace);
    li {
      button {
        opacity: 0.5;
      }
    }
  }
  ::v-deep .sf-bullet--active {
    --bullet-box-shadow: none;
    --bullet-background: var(--lace);
    opacity: 1 !important;
  }
  ::v-deep .cms-hero-banner {
    .cms-hero-banner__content-title {
      color: var(--white);
      font-size: 60px;
      margin-bottom: 15px;
      @include for-mobile {
        font-size: 45px;
      }
    }
    .cms-hero-banner__content-text {
      margin: 0 auto;
      padding: 0;
      p {
        b {
          span {
            font-weight: 500;
            font-size: 18px;
            @include for-mobile {
              font-size: var(--font_size_75);
            }
          }
        }
      }
    }

    .sf-button {
      width: 250px !important;
      @include for-mobile {
        width: 200px !important;
      }
      &:last-child {
        margin-top: var(--spacer-sm);
      }
    }
  }
}
</style>
